




















































































































.platform-page {
  width: 100%;
  position: relative;
  margin-bottom: 1.25rem;
  .box-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    padding: 0 0;
    min-height: 40px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 7px 10px 2px #00000038;
    .navbar-link {
      width: 100%;
      max-width: 200px;
      min-width: 120px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &:first-child {
        a {
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
        }
      }
      a {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        text-decoration: none;
        &.active {
          border-bottom: 3px solid #009751;
          background: linear-gradient(89deg, #00975146, #8ec63f75);
        }

        .text {
          color: #23222f;
          font-weight: 600;
          font-size: 20px;
          text-transform: uppercase;
        }
        .icon {
          img {
            height: 20px;
            width: auto;
          }
        }
      }
    }
  }
  .content-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 2rem 0;
    padding: 15px;
    border-radius: 15px;
    .card {
      box-shadow: 0px 0px 10px 2px #00000038;
      border-radius: 10px;
      min-height: 400px;
      .card-header {
        color: #2f394e;
        font-weight: 700;
        font-size: clamp(16px, 2vw, 20px);
        text-transform: uppercase;
        text-align: center;
      }
      .card-body {
        padding: 0;

        .banner {
          text-align: center;
          background-image: url(~@/assets/images/background/overlay.png);
          padding: 15px 0;
          border-radius: 0;
          width: 100%;
        }
        .text {
          padding: 10px 1.25rem;
          color: #2f394e;
          font-weight: 600;
          min-height: 175px;
          span {
            color: #009751;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .card-footer {
        text-align: center;
        min-height: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #2f394e;
          font-weight: 600;
          button {
            border-radius: 20px;
            padding: 5px;
            min-width: 120px;
          }
        }
      }
    }
  }
}
